import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import {
    catchError,
    map,
    filter,
    finalize,
    switchMap,
    take,
    tap,
} from "rxjs/operators";
// import { AuthService } from './auth.service';
import { of } from "rxjs";
import { ConstantsService } from "./../../shared/services/constants.service";
import {
    HttpClient,
    HttpClientModule,
    HttpHeaders,
} from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { HttpServiceService } from "src/app/shared/services/http-service.service";
@Injectable()
export class JwtAuthInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false;
    beforeLoginheader = {
        Authorization: "Basic " + btoa("android-client:android-secret"),
        "Content-Type": "application/x-www-form-urlencoded",
    };
    private refreshTokenSubject = new BehaviorSubject<any>(null);
    constructor(
        private http: HttpClient,
        private httpService: HttpServiceService,
        public router: Router,
        private toastr: ToastrService
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        let NewDate = localStorage.getItem("date");
        let date = new Date().getDate();

        if (NewDate) {
            if (date - parseInt(NewDate) != 0) {
                // this.getAllData = true;
                // window.location.reload();
                // localStorage.setItem("date", JSON.stringify(date))
            } else {
                // this.loadingView = false;
                // this.getAllData = false;
            }
        } else {
            // this.getAllData = true;
            // this.loadingView = false;
            localStorage.setItem("date", JSON.stringify(date));
        }
        return next.handle(this.addAuthToken(request)).pipe(
            catchError((requestError: HttpErrorResponse) => {
                if (requestError.status === 401) {
                    if (this.refreshTokenInProgress) {
                        return this.refreshTokenSubject.pipe(
                            filter((result) => result),
                            take(1),
                            switchMap(() =>
                                next.handle(this.addAuthToken(request))
                            )
                        );
                    } else {
                        this.refreshTokenInProgress = true;
                        this.refreshTokenSubject.next(null);
                        return this.refreshAuthToken().pipe(
                            switchMap((token) => {
                                this.storeTokenDetails(token);
                                this.refreshTokenSubject.next(token);
                                return next.handle(this.addAuthToken(request));
                            }),
                            catchError((err) => {
                                this.handleSessionExpiry();
                                return throwError(err);
                            }),
                            finalize(
                                () => (this.refreshTokenInProgress = false)
                            )
                        );
                    }
                } else {
                    this.httpService.pushErrorLogToLambda(
                        request.url,
                        request.body,
                        requestError
                    );
                    return throwError(requestError);
                }
            })
        ) as Observable<HttpEvent<any>>;
    }
    addAuthToken(request: HttpRequest<any>) {
        const token = localStorage.getItem("access_token");
        if (!token) {
            return request;
        }
        if (request.url.match(/token/gi) && !request.url.match(/save/gi)) {
            return request.clone({
                setHeaders: {
                    Authorization:
                        "Basic " + btoa("android-client:android-secret"),
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            });
        }
        if (
            request.url.match(/details.pristyntech.com/gi) ||
            request.url.match(/docker-parse/gi)
        ) {
            return request.clone({
                setHeaders: {
                    // Authorization: `Bearer ${token}`,
                },
            });
        } else {
            return request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                },
            });
        }
    }
    refreshAuthToken() {
        const httpOptionsAfter: { headers } = {
            headers: new HttpHeaders(this.beforeLoginheader),
        };
        return this.http
            .post(
                environment["routeUrl"] + "oauth/token",
                "grant_type=refresh_token&refresh_token=" +
                    localStorage.getItem("refresh_token"),
                httpOptionsAfter
            )
            .pipe(tap((x) => {}));
    }
    private storeTokenDetails(token: any): void {
        Object.entries(token).forEach(([key, value]) => {
            let stringValue: string;
            if (value === null || value === undefined) {
                stringValue = "";
            } else if (typeof value === "object") {
                stringValue = JSON.stringify(value);
            } else {
                stringValue = String(value);
            }
            localStorage.setItem(key, stringValue);
        });
        localStorage.setItem("dashboard", "medical");
        localStorage.setItem("isLoggedin", "true");
    }
    private handleSessionExpiry(): void {
        this.toastr.error("SESSION HAS EXPIRED\nPLEASE LOGIN AGAIN");
        localStorage.clear();
        this.router.navigate(["/login"]);
    }
}
